import React from 'react'
import MainLayout from '../layouts/Main'
import '../Components/Page/SelfServicePlatformTOS/SelfServicePlatformTOS.scss'
import { Link as ScrollLink } from "react-scroll";
import { Helmet } from 'react-helmet';



function SelfServiceTOS({props}) {

  return (
    <MainLayout
    headerPattern="/images/patterns/pattern-19.svg"
    footerPattern="/images/patterns/pattern-02.svg"
    >

<Helmet>
        <title>Self Service Platform TOS</title>
        <meta name="description" content="Terms of service for the self service platform describing definitions, rights and limiations on use, fees, term and termination, service data and account, confidentiality, intellectual property, warranties, disclaimers, limitation of liability, and indemnification." />
        <meta name="keywords" content="Wunderkind, self service, fees, rights, limitations, confidentiality, service, data, account, intellectual, property, liability" />
        <meta name="author" content="Wunderkind Corporation" />
      </Helmet>
        <div className="container self-service-container">
            <h1>Wunderkind Terms of Service</h1>
            <br />
            <h3>Table of Contents</h3>
        <ol>
            <li className='cursor-pointer uppercase'><ScrollLink to="definitions" smooth={true} duration={500}><strong>Definitions</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="rights-and-limitations" smooth={true} duration={500}>Rights and limitations on <strong>use</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="fees" smooth={true} duration={500}><strong>Fees</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="term-and-termination" smooth={true} duration={500}>Term and <strong>termination</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="service-data-and-account" smooth={true} duration={500}>Service data and <strong>account</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="confidentiality" smooth={true} duration={500}><strong>Confidentiality</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="intellectual-property" smooth={true} duration={500}>Intellectual <strong>Property</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="warranties" smooth={true} duration={500}>Warranties; Warranty <strong>Disclaimer</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="limitation-of-liability" smooth={true} duration={500}>Limitation of liability; <strong>indemnification</strong></ScrollLink></li>
            <li className='cursor-pointer uppercase'><ScrollLink to="additional-terms" smooth={true} duration={500}>Additional <strong>terms</strong></ScrollLink></li>
        </ol>

            <h3>THIS IS A LEGAL AGREEMENT BETWEEN COMPANY AND WUNDERKIND. COMPANY AND WUNDERKIND
MAY EACH BE REFERRED TO AS A “PARTY” AND COLLECTIVELY AS THE “PARTIES.” BY ACCESSING AND/OR
USING THE SERVICES, COMPANY IS AGREEING, ON BEHALF OF AN INDIVIDUAL AND/OR A LEGAL ENTITY,
TO BE BOUND BY THE TERMS OF THIS AGREEMENT.</h3>
<ol>
    <li id='definitions'><h2 className='uppercase'>Definitions</h2></li>
    <p>As used in the Agreement, the following defined terms shall apply:</p>
    <ul>
        <li>
            <p>1.1. <strong>Affiliate</strong> means, with respect to a Party, any entity that directly or indirectly controls, is controlled by,
or is under common control with such Party, where “control” means the power, directly or indirectly, to
direct, or to cause the direction of, the management and policies of an entity, through majority ownership
of voting securities or equity interests.</p>
        </li>
        <li>
            <p>1.2. <strong>Agreement</strong> means these Terms of Service and any other documents incorporated herein by
reference.</p>
        </li>
        <li>
            <p>1.3. <strong>Confidential and Proprietary Information</strong> includes, but is not limited to: (a) all financial, technical,
commercial and other information provided by the Disclosing Party or any of the Disclosing Party’s
Representatives to the Receiving Party or any of the Receiving Party’s Representatives, regardless of the
form of communication, concerning, among other things, Disclosing Party’s business strategies, financial
position, operations or assets including the Disclosing Party’s Intellectual Property Rights; financial
information and data; research and development plans; methods, data and processes; technical data;
manufacturing and production data business development; marketing and sales plans; and the identities
of, discussions with and the course of dealing with any actual and prospective customers, contractors,
vendors and other suppliers; (b) all notes, analyses, compilations, forecasts, studies, interpretations, and
other documents prepared by the Receiving Party or any of the Receiving Party’s Representatives which
contain, reflect or are based upon, in whole or in part, any information furnished by the Disclosing Party or
any of the Disclosing Party’s Representatives and (c) passwords or other security or authentication device
with respect to a Platform.  Failure to mark any Confidential and Proprietary Information as “confidential”
or “proprietary” shall not affect its status as Confidential and Proprietary Information under the terms of
this Agreement.   As used in this Agreement “Confidential and Proprietary Information” shall not be
construed to include information that:  (i) was in the public domain prior to or after the time of disclosure,
through no improper action of the  Receiving Party; (ii) was in the possession of the Receiving Party at the
time of disclosure, as shown by the Receiving Party’s files and records immediately prior to the time of
disclosure; or (iii) is received by the Receiving Party from a third party, provided that such Confidential and
Proprietary Information was not obtained by such third party, directly or indirectly, from the Disclosing
Party and provided further that such third party is not known to the Receiving Party to be bound by a
confidentiality   agreement   or   to   be   otherwise   prohibited   from   transmitting   the   information   by   a
confidential, legal or fiduciary obligation.</p>
        </li>
        <li>
            <p>1.4. <strong>Company</strong> means the legal entity or individual that has ordered access to the Platform and Services
from Wunderkind.</p>
        </li>
        <li>
            <p>1.5. <strong>Company Account</strong> means an account for Company that is required to access and use the Platform and
Services.</p>
        </li>
        <li>
            <p>1.6. <strong>Company Content</strong> means all data or information submitted by or on behalf of Company to the
Services</p>
        </li>
        <li>
            <p>1.7. <strong>Company Communications</strong> means communications sent via the Platform or Services, including emails
through a Third Party Partner or sub-processor, SMS and MMS messages, communications through
websites and transactions, and other similar communications.</p>
        </li>
        <li>
            <p>1.8. <strong>Documentation</strong> means the online user guides and other technical material relating to the use of the
Platform or Services, including any applicable service descriptions that are made available by Wunderkind
to the Company, as may be updated from time to time.</p>
        </li>
        <li>
            <p>1.9. <strong>Fees</strong> means all Wunderkind fees and charges applicable to the Platform and Services.</p>
        </li>
        <li>
            <p>1.10. <strong>Intellectual Property Rights</strong>means , in any jurisdiction whether registered or unregistered, including
rights in and to (a) trade secrets, know-how and other confidential or non-public business information; (b)
original works of authorship, copyrights, and any moral rights relating thereto; (c) trademarks, trade
names, service marks, trade dress, logos, other indications of origin, and any goodwill therein; (d) patents,
patent applications, and any and all divisions, continuations, continuations-in-part and extensions thereof,
invention disclosures, inventions, discoveries and improvements; (e) Internet domain names; (f) social
media accounts; and (h) claims, causes of action, defenses and the right to sue and collect damages
relating to the enforcement of the foregoing; in each case of (a) – (e), including any registrations of
applications to register and renewals and extensions of, any of the foregoing with or by any governmental
entity in any jurisdiction.</p>
        </li>
        <li>
            <p>1.11. <strong>Order Form</strong> means any online or written form or other communication provided by Wunderkind
evidencing Company’s subscription to the Platform or Services.</p>
        </li>
        <li>
            <p>1.12. <strong>Platform</strong> means certain Wunderkind software platforms and/or proprietary technologies, including
but not limited to Text, Connect, Studio, Behavioral Email, Website Personalization, Identity.</p>
        </li>
        <li>
            <p>1.13. <strong>PHI</strong> means personal health information covered by US HIPAA regulations. PHI shall not be uploaded
into any portion of the Platform(s).</p>
        </li>
        <li>
            <p>1.14. <strong>Representatives</strong> means a Party’s directors, officers, employees, agents, advisors (including financial
advisors and legal counsel) and or partners and the directors, officers, partners and employees of any such
agents, advisors or partners, collectively.</p>
        </li>
        <li>
            <p>1.15. <strong>Service Data</strong> means information gathered in connection with Company’s use of Platforms or Services,
including, without limitation, Company Content and personal data, including personal data of end
customers, prospects, and Users.</p>
        </li>
        <li>
            <p>1.16. <strong>Services</strong> means the Wunderkind Platform and/or services offerings inclusive of any services
delivered by Wunderkind, and Updates. Wunderkind may update the Services with Updates at any time in
its sole discretion.</p>
        </li>
        <li>
            <p>1.17. <strong>Site(s)</strong> means Company’s website(s) wherein the Platform and/or Services is implemented.</p>
        </li>
        <li>
            <p>1.18. <strong>Term</strong> means, together, the Initial Term and any Extended Term(s) (both as defined in an applicable
Order Form) during which Company’s Users are authorized to use or access the Platform and Services
pursuant to the terms set forth in this Agreement, unless earlier terminated as set forth in Section 4 below.</p>
        </li>
        <li>
            <p>1.19. <strong>Taxes</strong> means all applicable taxes on Services (including but not limited to withholding tax, sales tax,
services tax, value-added tax (VAT), goods and services tax (GST), and tariffs and/or duties) imposed by any
government entity or collecting agency based on the Services.</p>
        </li>
        <li>
            <p>1.20. <strong>Third Party Seller</strong> means an entity other than Wunderkind from whom a Company procures access to
the Platform and Services.</p>
        </li>
        <li>
            <p>1.21. <strong>Updates</strong> means any corrections, bug fixes, features or functions added to or removed from the
Services but shall not include any new Service(s) not generally included with the Services purchased by Company.</p>
        </li>
        <li>
            <p>1.22. <strong>User</strong> means an individual that is authorized by Company to access the Platform and Services through
Company’s assignment of a user ID.</p>
        </li>
        <li>
            <p>1.23. <strong>Wunderkind</strong> means Wunderkind Corporation, a Delaware Corporation.</p>
        </li>
        <li>
            <p>1.24. <strong>Wunderkind Materials</strong> means any material(s) that Wunderkind provides to Company as part of, or in
the course of providing, the Services or the consulting services.</p>
        </li>
    </ul>
    <li id='rights-and-limitations'><h2 className='uppercase'>Rights and limitations on use</h2></li>
    <ul>
        <li>
            <p>2.1. <strong>Right to Use Services for Business</strong> Subject to these terms, Wunderkind and/or its Affiliates, will
provide Services set forth in any Order Form or Order that Wunderkind has accepted in accordance with
this Agreement. Company may permit its Users to use the Platforms or Services provided that Company
shall be responsible for each User’s compliance with the Agreement. Company acknowledges that
Services are not intended for use by consumers and are only for business and professional purposes as
expressly granted in this Agreement. Wunderkind hereby grants Company a limited, revocable, non-
exclusive, non-assignable, non-sublicensable, non-transferable right to access and use the Platform and
Services under Wunderkind’s Intellectual Property Rights therein during the applicable term in connection
with the applicable Company website(s) in the applicable field of use. Support may be provided pursuant
to a Statement of Work to an Order Form, as applicable. Updates to the Platform and Services are
managed by Wunderkind and included in the Fees. Company shall use the then-current version of the
Platform and/or Services, including any Updates, as made available by Wunderkind. To the extent that
Company’s Affiliates use the Services, Company warrants that it has the authority to bind those Affiliates
and that Company will be liable to Wunderkind in the event any Company Affiliate fails to comply with this
Agreement. At its sole discretion, Wunderkind may modify or discontinue any part of the Services
(including without limitation, the availability of any feature) at any time.</p>
        </li>
        <li>
            <p>2.2. <strong>Restrictions.</strong> The Company agrees that it will not (and will not allow any third-party to), without the
prior written consent of Wunderkind, (i) copy, reproduce, create derivative works of, disclose or publicly
display a Platform; (ii) decompile, disassemble, reverse engineer, or otherwise attempt to determine the
functionality of a Platform (other than to the extent permitted by, and in accordance with, applicable law
which cannot be excluded by the agreement of the parties); (iii) directly or indirectly license or sublicense,
distribute, resell, rent, lease, subcontract, operate as a service bureau or otherwise make a Platform
available to any third party; (iv) develop a product or service using ideas, features, functions or graphics
similar to those in a Platform; (v) disclose or share any passwords or other security or authentication
device with respect to a Platform to any third party, gain or attempt to fain unauthorized access to a
Platform or circumvent or otherwise interfere with any authentication or security measures of the
Platform; (vi) use a Platform for a purpose other than that set forth in this Agreement, or otherwise in a
manner   that   violates   any   applicable   law,   rule   or   regulation;   (vii)   remove,   conceal   or   alter   any
identification, copyright or other proprietary rights notices or labels on a Platform; (viii) transmit through
or post on the Services any material that is deemed abusive, harassing, obscene, slanderous, fraudulent,
libelous or otherwise unlawful or tortious, including material harmful to children or violative of third-party
privacy rights; or (ix) upload any PHI to the Platform and/or Services.</p>
        </li>
        <li>
            <p>2.3. <strong>Inappropriate Content.</strong> Neither Company nor its Users may use the Platform or otherwise make use
of the Services in a manner that: (i) violates any applicable law (including export control laws and
regulations), Wunderkind’s or any third party’s intellectual property rights, or anyone’s right of privacy or
publicity; (ii) is deceptive, fraudulent, illegal, obscene, defamatory, disparaging, libelous, threatening, or
pornographic (including child pornography, which, upon becoming aware of, Wunderkind will remove and
report   to   law   enforcement,   including   the   National   Center   for   Missing   and   Exploited
Children); (iii) constitutes hate speech, harassment, or stalking; (iv) criticizes, berates or attacks others
based on their race, ethnicity, national origin, religion, sex, gender, sexual orientation, disability or medical
condition; (v) contains any personal information of minors under the age of 16; (vi) sends or stores
information deemed “sensitive” or “special” under applicable law, including but not limited to financial
account   information,   social   security   numbers,   government-issued   identification   numbers,   health
information, biometric or genetic information, personal information collected from children under the age
of 16, geo-location information of individuals or information about an individual’s racial or ethnic origin,
trade union membership, sex life or sexual orientation, political opinions or religious or philosophical
beliefs; (vii) contains viruses, bots, worms or similar harmful materials; or (viii) contains any information
that Company does not have a right to make available under law or any contractual or fiduciary duty, or
could otherwise cause damage to Wunderkind or any third party</p>
        </li>
        <li>
            <p>2.4. <strong>Violations of Limitations on Use.</strong> If Company becomes aware or receives notice from Wunderkind or
a third party that any Company Content or Service Data or any User’s access to or use of Company Content
or Service Data violates Section 2.2 or Section 2.3, Company must take immediate action to remove the
applicable part of the Company Content or Service Data and/or to suspend the User’s access to the
Services, as applicable. Wunderkind may ask Company to remediate such violation and, if Company fails to
comply with such request, Wunderkind may suspend or terminate the User’s access to the Services and/or
suspend Company’s access to the Services pursuant to Section 10.7.</p>
        </li>
        <li>
            <p>2.5. <strong>No Implied License.</strong> This Agreement, any Order Forms hereto and the conduct of the parties in
connection with this Agreement and such Order Forms are not intended to, and shall not be construed to,
convey any rights to Company or any third party under any Intellectual Property Rights of Wunderkind in
any manner, including as a result of patent exhaustion, estoppel or implied license doctrines in any
jurisdiction or any similar doctrine, whether by operation of law or otherwise; except the right to use
Platforms or Services under Wunderkind’s Intellectual Property Rights in accordance with Section 2.1.</p>
        </li>
        <li>
            <p>2.6. <strong>Responsibilities of Company</strong> Company shall be responsible for setting up and configuring the Services,
including without limitation any provisioning of access to the Platform and Services to its Users.  Company
shall   be   responsible   for   obtaining   and   maintaining,   at   Company’s   expense,   all   of   the   necessary
telecommunications, computer hardware, software, services and Internet connectivity required by
Company or any User to access the Platform or Services from the Internet. In the event that Wunderkind
assists or advises Company with any Platform or Services setup, configuration or support, in no event shall
such assistance or advice be construed as legal advice.  The Company shall create a username, password,
and other profile data points for accessing Platforms (“User Information”). The Company acknowledges
and agrees that the User Information is unique to the Company and shall be maintained in strict
confidence. The Company shall implement and maintain all such administrative, physical and technical
safeguards as may be necessary to prevent unauthorized users from gaining entry or access to Platforms.
In the event Company believes Company’s account or the Platform or Services have been compromised,
including any unauthorized use or access of the Platform or Services or any other known or suspected
breach of security, Company shall immediately notify Wunderkind by email to privacy@wunderkind..co,
but in no event more than twenty-four (24) hours following discovery of such breach. The Company shall
limit access to Platforms to its employees who are aware of and have acknowledged the terms of this
Agreement.   The Company shall not access Platforms for purposes of monitoring its availability,
performance, or functionality, or for any other benchmarking or competitive purposes.</p>
        </li>
        <li>
            <p>2.7. <strong>Analytics Services.</strong> The Company shall provide to Wunderkind empirical data gathered by the
Company’s analytics provider(s) (“Analytics Data”) and the Company hereby grants to Wunderkind a right
to use the Analytics Data solely for the purpose of providing the Services described herein and/or in an
applicable Order Form.</p>
        </li>
        <li>
            <p>2.8. <strong>Downtime</strong> Company acknowledges that access to and use of the Platform and Services may be
suspended for the duration of any scheduled or unscheduled downtime or unavailability of any portion or
all of the Platform and Services for any reason, including as a result of power outages, system failures or
other interruptions, or any other acts, omissions or failures on the part of Wunderkind.</p>
        </li>
    </ul>
    <li id='fees'><h2 className='uppercase'>Fees</h2></li>
    <ul>
        <li>
            <p>3.1. <strong>General</strong> In consideration for the Services, Company will pay to Wunderkind the then-current fees set
forth in the “Account” section of Company’s account in the Platform unless otherwise set forth in an
applicable Order Form (including applicable overages, “Fees”). For self-service Company subscriptions
where auto-upgrade billing applies through opt-in or as described during the check-out process,
subscriptions will automatically upgrade according to usage, and renewals pursuant to Section 4.3 will
renew at such upgraded subscription fee amount. Wunderkind shall be entitled to withhold performance
and suspend or discontinue the Platform or Services until all amounts due are paid in full. In the event of a
temporary suspension of Company’s access to the Platform and Services, applicable Fees will continue to
accrue.</p>
        </li>
        <li>
            <p>3.2. <strong>Invoicing by Wunderkind</strong> Unless otherwise indicated in an applicable Order Form, Wunderkind will
invoice the applicable Fees quarterly in advance which shall each cover the upcoming three months of the
Term. Invoices should be submitted to the Company contact identified on the applicable Order Form,
unless otherwise expressly stated.  Unless otherwise expressly stated in an Order Form, invoices are due
and payable within 15 days of receipt by Credit Card or ACH.  The Company shall be responsible for all wire
fees.  Company shall provide all information necessary to ensure timely payment of any fees as set forth in
an Order Form to this Agreement, and represents to Wunderkind that no purchase order number will be
necessary to fulfill Company’s payment obligations.  If the Company fails to pay Wunderkind an invoice in
accordance with this Agreement or an Order Form, then, without prejudice to any other rights
Wunderkind may have, Wunderkind shall be entitled to suspend Platform access and its provision of
Services hereunder.  All payments are exclusive of federal, state, local and foreign taxes, duties, tariffs,
levies, withholdings and similar assessments (including sales taxes, use taxes and value added taxes), and
Company agrees to bear and be responsible for the payment of all such charges, excluding taxes based
upon Wunderkind’s net income.  Unpaid amounts are subject to a finance charge of 1.5% per month on
any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of
collection (including reasonable attorney’s fees).</p>
        </li>
        <li>
            <p>3.3. <strong>Third-Party Seller</strong> Notwithstanding anything to the contrary in this Section 3, if Company purchases a
license to use the Platform and Services through a Third-Party Seller, payment terms shall be controlled by
Company’s agreement with such Third-Party Seller.</p>
        </li>
    </ul>
    <li id='term-and-termination'><h2 className='uppercase'>Term and termination</h2></li>
    <ul>
        <li>
            <p>4.1. <strong>Termination</strong> This Agreement may be immediately terminated by a party if: (i) Company commits a
material breach of its obligations hereunder that is not cured within thirty (30) days after written notice
thereof from Wunderkind, or (ii) a petition in bankruptcy or other insolvency proceeding is filed by or
against the Company, or if an application is made for the appointment of a receiver for the Company of its
property, or if the Company makes an assignment for the benefit of creditors, is unable to pay its debts
regularly as they become due, or ceases carrying on business in the ordinary course.</p>
        </li>
        <li>
            <p>4.2. <strong>Effect of Termination</strong> If this Agreement is terminated, then: (i) all license rights granted to Company
under this Agreement will be immediately revoked and Company shall cease using all Wunderkind
Intellectual Property Rights; (ii) Company will uninstall the Platform from Company’s Third-Party Seller
instance;  (ii) upon request, each party will use commercially reasonably efforts to return or destroy all
Confidential and Proprietary Information of the other party; and (iv) all Order Forms issued hereunder
shall be terminated. Sections 2, 4, and 6-10 shall survive termination of this Agreement. Termination of
this Agreement will not limit either party from pursuing any other remedies available, including injunctive
relief, nor will termination relieve Company of its obligation to pay all charges for work performed prior to,
and if applicable, following such termination.</p>
        </li>
        <li>
            <p>4.3. <strong>Term Renewals</strong> These Terms of Service commence on Company’s registration of an account, and
continue until all Platform and Services subscriptions in all Order Forms have expired or have been
terminated, and Company’s account has been closed. Unless otherwise specified on an applicable Order
Form, Company’s subscription to the Platform and Services commences on Company’s registration for an
account, and shall continue in effect for the selected subscription term length (the “Initial Term”).
Thereafter,   and   unless   otherwise   specified   in   a   Order   Form,   each   applicable   Order   Form   shall
automatically renew for successive subscription terms of one-year (each a “Renewal Term”), unless either
party provides notice to the other party of its intention not to renew prior to expiration of the Initial Term
or the then-current Renewal Term. A party must provide at least thirty (30) days prior notice if on any
other billing cycle. Notification of such non-renewal from Company to Wunderkind must be provided in
accordance with Section 10.18, which must be confirmed by Wunderkind. The Initial Term and all Renewal
Terms will collectively be referred to as the “Term”.</p>
        </li>
    </ul>
    <li id='service-data-and-account'><h2 className='uppercase'>Service Data and Account</h2></li>
    <ul>
        <li>
            <p>5.1. <strong>Service Data.</strong>Company retains all rights to any and all of its Service Data, subject to a non-exclusive,
transferable, sublicensable, worldwide, royalty-free license to Wunderkind as necessary to provide the
Services hereunder. Each Party shall apply reasonable technical, organizational, and administrative
security measures, as appropriate relative to the Services, to keep Service Data protected in accordance
with industry standards. If Wunderkind reasonably believes a problem with the Services may be
attributable to Company Content or Company’s use of the Services, Company shall fully cooperate with
Wunderkind to identify the source of and resolve the problem. Company shall comply with all applicable
laws and obligations related to the Service Data, as well as all legal duties applicable to Company by virtue
of using the Services, including providing all required information and notices and obtaining all required
consents. Wunderkind shall retain Service Data only for as long as necessary to provide the Services to
Company. Upon termination of this Agreement for any reason, Wunderkind shall erase, delete, or destroy
all or any part of such Service Data in accordance with Wunderkind’s policy. This Agreement states
Wunderkind’s exclusive obligations with respect to care of Service Data.</p>
        </li>
        <li>
            <p>5.2. <strong>Company Account</strong> Company is solely responsible for (i) the configuration of its Company Account; (i
i) the operation, performance and security of Company’s equipment, networks and other computing
resources used to connect to the Services; (iii) ensuring all Users exit or log off from the Services at the end
of each session in accordance with Company’s session policy; (iv) maintaining the confidentiality of a
Company   Account,   User   Information,   passwords   and/or   personal   identification   numbers   used   in
conjunction with the Services, including not sharing login information among Users; and (v) all uses of the
Services that occur using Company’s password or Account. Company will notify Wunderkind immediately
of any unauthorized use of its Company Account or any other breach of security relating to Company’s use
of the Platform and Services. Company will be liable for losses, damages, liability, expenses, and attorneys’
fees incurred by Wunderkind or a third party due to someone other than a User using a Company Account.
Ownership of a Company Account is directly linked to the individual or entity that completes the
registration process for such Company Account. Company acknowledges that Wunderkind will rely on the
information provided by Company to resolve issues arising with the Company Account.</p>
        </li>
        <li>5.3. <strong>Company Account Access/Instructions</strong> The Company Account owner and any authorized Users will
have access to information in the Company Account. Wunderkind will not provide access to any other
person or entity at any time, except with the Company Account owner’s prior consent. Company agrees
Wunderkind may rely on instructions given by the Company Account owner either through the Account
dashboard or via email from the address on file at Wunderkind for the Company Account owner. Company
agrees not to request access to or information about an account that is not owned by the Company. In the
event of a dispute regarding Company Account data, Wunderkind will only release information to persons
other than the Company Account owner pursuant to a court order or other notarized waiver and release
as determined by Wunderkind. Company is solely liable and responsible for understanding the settings,
privileges, and controls for the Services and for controlling whom Company permits to become a User and
the settings and privileges for such User, including, without limitation, the right for a User to invite other
Users (either paid or unpaid) to use Company’s Services, the right to incur charges on the Company
Account, and the right to access, modify and/or delete Company Data and the security of all passwords
and other account information required in order to access and use the Platform or Services. Company is
responsible for the activities of all of its Users, even if those Users are not from Company’s organization or
domain.</li>
    </ul>
    <li id='confidentiality'><h2 className='uppercase'>Confidentiality</h2></li>
    <ul>
        <li>
            <p>6.1. In the course of performance under this Agreement, each party will have access to Confidential and
Proprietary Information of the other party. The parties desire to protect the Confidential and Proprietary
Information disclosed by either party (the “<strong>Disclosing Party</strong>”) to the other party (the “<strong>Receiving Party</strong>”) or
its Representatives.  For the avoidance of doubt, the Platform, the Services, Documentation, and all
enhancements and improvements thereto, will be considered Confidential Information of Wunderkind.</p>
        </li>
        <li>
            <p>6.2. The Confidential and Proprietary Information shall be kept confidential by the Receiving Party and its
Representatives and neither the Receiving Party not any of its Representatives shall use any Confidential
and Proprietary Information for any purpose except as reasonably necessary to exercise their respective
rights and perform their respective obligations under this Agreement.</p>
        </li>
        <li>
            <p>6.3. The Receiving Party shall not disclose any Confidential and Proprietary Information to third parties
other than the Receiving Party’s Representatives without the Disclosing Party’s prior written consent. The
Receiving Party shall disclose the Confidential and Proprietary Information only to those Representatives
who have a need to know. The Receiving Party shall require its Representatives who have access to the
Confidential and Proprietary Information to sign or have signed a non-disclosure or other equivalent
agreement that protects the Confidential and Proprietary Information. A breach of this Agreement by a
Representative of the Receiving Party shall be deemed a breach of this Agreement by the Receiving Party.
The Receiving Party shall take reasonable measures to protect the secrecy of, and avoid the unauthorized
disclosure or use of, the Confidential and Proprietary Information. </p>
        </li>
        <li>
            <p>6.4. The parties agree that the unauthorized disclosure or use of the other party’s Confidential or
Proprietary Information would cause irreparable injury to the other party. Accordingly, a party will be
entitled to injunctions and other equitable remedies in the event of such disclosure or use, in addition to
whatever remedies it may have at law. </p>
        </li>
    </ul>
    <li id='intellectual-property'><h2 className='uppercase'>Intellectual Property</h2></li>
    <ul>
        <li>
            <p>7.1. <strong>General</strong> All right, title and interest in and to all Platform, the Services, and all Intellectual Property Rights therein, and any enhancements, derivative works or improvements thereto are and shall remain exclusively with Wunderkind, its licensors and their respective successors and assigns. Company is not granted any ownership right, title, or interest whatsoever in any Platform, Services, or other Confidential and Proprietary Information, or any Intellectual Property Rights associated with the foregoing. To the extent Company provides any verbal or written ideas or feedback to Wunderkind concerning any Platform or Services(“<strong>User Feedback</strong>”), Company hereby assigns, transfers and conveys to Wunderkind, or its designees, all worldwide right, title, and interest in and to any and all User Feedback, including any ideas, inventions, discoveries, original works of authorship, findings, conclusions, concepts, and improvements in such User Feedback. </p>
        </li>
        <li>
            <p>7.2. <strong>Company Use of IP</strong> Company shall not use the copyrights, domain names, trademarks, trade names, service marks, logos, or other property rights associated with Wunderkind, any Platform or Services or any other Wunderkind product or service without the express written consent of Wunderkind. Company shall not register, attempt to register, or assist anyone else in registering any copyright, domain name, trademark, trade name, service mark, logo, or other proprietary rights associated with Wunderkind, a Platform, or any other Wunderkind platform or service. Company authorizes Wunderkind to use Company’s name, logos and trademarks in Wunderkind’s promotional materials, website, and for publicity purposes.</p>
        </li>
        <li>
            <p>7.3. <strong>Consent to Use of Data.</strong> The Company agrees that Wunderkind may use Service Data to improve Platforms or Services, and Wunderkind’s products and services, as long as such information is in a form that does not individually Identify Company or personally identify end users of Company’s website(s).</p>
        </li>
        <li><p>7.4. <strong>Wunderkind Identity Network</strong> Company grants to Wunderkind, the right to access and use Service Data (including pseudonymous identifiers) in connection with the provision of its device and Wunderkind Privacy ID program (the “Program”) in which Company and other companies participating in the Program (together, the “Participants”) permit Wunderkind additional rights to collect and use data generated by Company’s use of Platforms and Services to better recognize the end-users of Participants’ websites and/or improve or personalize the content advertising or marketing communications, to any and all Participants which benefit other Participants in the Program by: (i) running marketing or advertising campaigns against such Service Data, including cross-device marketing or advertising campaigns where Wunderkind is able to infer that a particular browser, device, or pseudonymous identifier belongs to the same end user, (ii) onboarding offline data, (iii) optimizing message and ad delivery receptivity (e.g., based on time of day and/or open rate), or (iv) creating profiles using Service Data provided that such profiles are created in compliance with Section 7.3. A Participants’ cookie or other first-party ID data included in the Service Data will not be accessed by or transferred to any other Participant. Company also grants Wunderkind the right to disclose Service Data for use in connection with the Program and related Wunderkind services, as long as any disclosure of such data is aggregated, anonymized or otherwise does not individually identify the Company.</p>
</li>
<li>
    <p>7.5. <strong>Addressability Data</strong> To the extent Wunderkind provides or makes available to Company any pseudonymous data derived from, stored in, or otherwise relating to the pixel tags, cookies, web beacons, mobile advertising identifiers and/or other pseudonymous information provided or generated by Wunderkind in the performance of the Program <strong>("Addressability Data"),</strong>Wunderkind grants to Company, during the Term, the right to use and benefit from the Addressability Data solely in connection with Company’s participation in the Program. Wunderkind is not obligated to disclose to Company the identity of any Participant. Wunderkind reserves the right to cease all use of Addressability Data upon notification to Customer. As between Wunderkind and Company, Wunderkind will own all rights in and to all Addressability Data.</p>
</li>
<li>
    <p>7.6. <strong>Continuous Development</strong> Company acknowledges that Wunderkind may continually develop, deliver and provide to Company on-going innovation to the Platform and Services in the form of new features, functionality, and efficiencies. Accordingly, Wunderkind reserves the right to modify the Platform and Services from time to time. Some modifications will be provided to the Company at no additional charge. In the event Wunderkind adds additional functionality to a particular Platform or Service, Wunderkind may condition the implementation of such modifications on Company’s payment of additional fees, provided that Company may continue to use the version of the Platform or Services that Wunderkind makes generally available (without such features) without paying additional fees.</p>
</li>
<li>
    <p>7.7. <strong>WunderkindAI</strong> WunderkindAI and its machine learning methods and data models may develop over time to better address specific use cases.  Wunderkind may use Service Data to improve Wunderkind’s models in accordance with Section 7.4.  Additionally, the Company's use of WunderkindAI is subject to fair usage restrictions that Wunderkind may determine in its sole discretion. Company acknowledges and agrees that if Company exceeds what Wunderkind, in its sole discretion, determines to be fair usage, Wunderkind may disable or degrade the performance of WunderkindAI.</p>
</li>
<li>
    <p>7.8. <strong>Copyright.</strong>Wunderkind reserves the right to delete or disable any Company Content, including WunderkindAI output, allegedly infringing copyright(s); to require that Company terminate the accounts of Users who are repeat copyright infringers; and to forward the information in any copyright-infringement notice to the User who allegedly provided the infringing content. We respect the Intellectual Property Rights of others, and we expect our users to do the same. We will respond to clear notices of copyright infringement consistent with the Digital Millennium Copyright Act (“DMCA”).
    </p>
</li>
    </ul>
    <li id='warranties'><h2 className='uppercase'>Warranties; Warranty Disclaimer</h2></li>
    <ul>
        <li>
            <p>8.1. <strong>To the maximum extent permitted by applicable law, except as expressly set out in this Section 8,
Wunderkind provides no other representations and warranties of any kind, whether express, implied,
statutory or otherwise, and Wunderkind specifically disclaims all implied warranties including
warranties of merchantability or fitness for a particular purpose or non-infringement.</strong></p>
        </li>
        <li>
            <p>8.2. <strong>Company Representations and Warranties.</strong> Company represents and warrants that: (i)  it is entitled
to transfer the Service Data to Wunderkind so that Wunderkind and its sub-processors and Affiliates may
lawfully use, process, and transfer the Service Data in accordance with this Agreement on Company’s
behalf; (ii) use of the Customer Data (a) will not infringe, misappropriate, or otherwise violate the
Intellectual Property Rights or other rights of any third party, (b) will not constitute defamation, invasion of
privacy or publicity, or otherwise violate any similar rights of any third party, and (c) will not be used in any
activity in violation of the law or to promote such activities, including, without limitation, in a manner that
might be illegal or harmful to any person or entity; (iii) it will not distribute, share, or facilitate the
distribution of unauthorized data, malware, viruses, Trojan horses, spyware, worms, or other malicious or
harmful code; and (iv) Customer will comply with and will at all times honor the terms and conditions of
any on-line coupon or similar marketing offer, deal or advertisement to market Customer’s products and
services offered or otherwise promoted via the Services; (iv) it is the Company’s obligation to comply with
the ADA and its state and local equivalents, including, but not limited to, with respect to website
accessibility and mobile application accessibility via compliance with generally recognized guidelines
governing accessible website and mobile application design (which Company recognizes is currently the
Web Content Accessibility Guidelines (“WCAG”) 2.1 at Levels A and AA).</p>
        </li>
        <li>
            <p>8.3. <strong>Mutual Representations and Warranties</strong> Both parties agree to comply with all laws and regulations
applicable to such party or such party’s performance under this Agreement, including CAN-SPAM Act of
2003 and all obligations with respect to the retention and updating of unsubscribe records. Both parties
agree to comply with all applicable privacy and data protection laws and comply with applicable industry
self-regulatory obligations, such as the NAI, eDAA, and Digital Advertising Alliance (“DAA”) Code location
at <a target='_blank' href='http://www.aboutads.info/'>http://www.aboutads.info/</a>, as well as laws and regulations applicable to the protection of personally
identifiable information, privacy, credit information, and sensitive data.</p>
        </li>
        <li>
            <p>8.4. <strong>Company Privacy Representations and Warranties</strong> Company will provide any required disclosures,
whether online or in store, to end users of the Site(s) (via a privacy policy, terms and conditions, or  etc.)
and obtain any necessary consents from such end users, regarding the Wunderkind Services and Platforms
provided by Wunderkind hereunder as described in Wunderkind’s applicable privacy policy. Company will
maintain and make available on each Company website a statement of the privacy practices of the
operator of that Site. Each privacy statement required by this section must clearly and conspicuously
disclose that Company and/or third-party partners may: (i) collect, use, and disclose information for
marketing purposes, including but not limited to interest based advertising, (ii) use pixels, cookies, and
similar non cookie-based tracking technologies; (iii) use information collected via the Site to send
marketing messages to users even when not logged into the Site; and (iv) collect information from forms
on the site prior to a website visitor hitting a submit button. Each privacy statement required by this
section   must   also   include   a   link   to   the   DAA   (or   similar   industry)   opt-out   link   located   at 
<a target="_blank" href='http://optout.aboutads.info/#!/'> http://optout.aboutads.info/#!/.</a></p>
        </li>
        <li>
            <p>8.4.1. For   Company’s   convenience,   Wunderkind   provides   the   following   sample   privacy
disclosure, but strongly encourages Company to work with counsel to ensure that these
disclosures accurately represent Company’s practices as legal requirements may change
and/or be subject to differing legal interpretations: “We use a third-party service provider to
serve ads and/or collect data on our behalf across the Internet and sometimes on this website.
Some of these service providers may collect information about your visits to our website, and
your interaction with our products and services to tailor marketing messages on this website
and other sites or to trigger real time interaction, customize this Web site or enhance your
profile.  Some of these service providers may be able to collect personal information that you
share with this site via a web form automatically and prior to your pressing a submit button;
and they may be able to use information from your visits to this site to send marketing
messages to you in a way that may personally identify you. This information is collected
through the use of a cookie, a javascript tag and/or pixel, which is industry standard
technology used by most major web sites.     The information collected by these service
providers may include your device's IP address, user agent, email addresses (where hashed or
otherwise   rendered   pseudonymous)   and   other   user   and   device   level   pseudonymous
information.  Please keep in mind that your browser settings may not permit you to control
the technologies utilized by these third-party companies.  If you would like more information
about these practices, please click: <a target='_blank' href='http://optout.aboutads.info/#!/.'>http://optout.aboutads.info/#!/.”</a></p>
        </li>
        <li>
            <p>8.5. <strong>Privacy.</strong>If Personal Data (as defined in the Terms) is processed by Wunderkind under the Agreement
the applicable terms indicated and set forth at <a target='blank' href='https://www.wunderkind.co/privacy/wunderkind-dpa/'>https://www.wunderkind.co/privacy/wunderkind-dpa/</a>
(the “Terms”) shall apply and are incorporated by reference into this Agreement as if included in full. For
the purposes of interpreting the Terms, the Agreement shall be deemed to be the “Addendum” and
“Agreement” which incorporate the Terms. If there is a conflict between the Agreement and the Terms,
the Terms will control with respect to the processing of Personal Data. Capitalized terms used but not
defined in the Terms have the same meanings given to them in the Agreement.</p>
        </li>
        </ul>
    <li id='limitation-of-liability'><h2 className='uppercase'>LIMITATION OF LIABILITY; Indemnification</h2></li>
    <ul>
        <li>
        <p>9.1. <strong>Limitation on Liability.</strong>EACH PARTY’S CUMULATIVE LIABILITY FOR DAMAGES, IF ANY, FOR ALL CLAIMS
OF ANY KIND ARISING IN CONNECTION WITH COMPLAINTS OF PERFORMANCE OR BREACH OF THIS
AGREEMENT OR AN ORDER FORM HERETO IS LIMITED TO THE CUMULATIVE AMOUNT OF THE FEES PAID
BY THE COMPANY PURSUANT TO THE APPLICABLE ORDER FORM FOR THE THREE (3) MONTHS PRIOR TO
THE DATE THE CAUSE OF ACTION AROSE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, IN NO
EVENT SHALL EITHER PARTY OR ANY OF ITS SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT,
CONSEQUENTIAL, INCIDENTAL, PUNITIVE, SPECIAL, OR SIMILAR DAMAGES, INCLUDING DAMAGES FOR
LOSS OF PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, LOSS
OF WORK PRODUCT, OR ANY AND ALL OTHER COMMERCIAL DAMAGES OR LOSSES, WHETHER IN TORT,
CONTRACT, OR OTHERWISE. THE LIMITATIONS OF LIABILITY SET FORTH IN THIS SECTION SHALL REMAIN
FULLY EFFECTIVE EVEN IF THE REMEDIES AVAILABLE TO A PARTY FAIL OF THEIR ESSENTIAL PURPOSE OR
ARE OTHERWISE HELD TO BE UNENFORCEABLE. The foregoing provisions shall be enforceable to the
maximum extent permitted by applicable law.</p>
        </li>
    </ul>

<ul>
    <li>
        <p>9.2. <strong>Company Indemnification</strong>The Company shall defend, indemnify, and hold harmless Wunderkind,
and its officers, directors, employees, agents, contractors, suppliers, and licensors against all costs,
expenses, losses, and damages (including reasonable attorneys’ fees and costs) incurred or awarded as a
result of or in connection with claims of third parties against Wunderkind based on or arising directly or
indirectly out of (i) the Company’s use of a Platform, (ii) a breach by the Company of any terms of this
Agreement or an Order Form,  (iii) the Company’s violation of any applicable laws or regulations including,
but not limited to, those governing accessibility, such as the Americans with Disabilities Act (“ADA”) and its
state/local equivalents); or (iv) any claim arising or related to a Campaign that includes a sweepstakes.</p>
    </li>
    </ul>
    <li id='additional-terms'><h2 className='uppercase'>Additional Terms</h2></li>
    <ul>
        <li>
            <p>10.1. <strong>Order Forms. </strong> Wunderkind and Company may execute order forms under this Agreement pursuant to
which Wunderkind will operate and maintain the certain Wunderkind Platform(s) and Service(s), as
further described in such Order Forms. All Order Forms and the Platforms and Services therein will be
subject to the terms of this Agreement. Additional subsidiaries and/or affiliates of Wunderkind may
provide services, under this Agreement as indicated per an applicable Order Form. Wunderkind will
remain responsible for all the actions or omissions by its affiliates and/or subsidiaries.</p>
        </li>
        <li>
            <p>10.2. <strong>Connect</strong>Subject to Wunderkind's obligations in this Agreement, the Company is responsible for all
actions taken on Connect and/ or Studio Platform (“Connect”) to the extent within Company’s control by
the Company or other authorized users acting directly on Company’s behalf.  Company shall: (i) have sole
responsibility for the accuracy and legality of all data input by Company within Connect (“Company
Content”); and (ii) use Connect and the Content in compliance with the Agreement and the Connect
Acceptable Use Policy, as set forth at https://www.wunderkind.co/terms/connect-terms/ (the “Connect
AUP”), which are incorporated into this Agreement by reference as if included in full.  Wunderkind may, in
its sole discretion, refuse to distribute any communication with end users of Company’s website(s) on
behalf of the Company directly through Connect (“Messages”) where the content of the Message or the
delivery of such Message is, in Wunderkind’s reasonable opinion, unlawful.  Capitalized terms used but not
defined in the Connect AUP have the same meanings given to them in this Agreement.</p>
        </li>
        <li>
            <p>10.3. <strong>Precedence.</strong> In the event any conflict or inconsistencies arise between the terms of this Agreement,
on the one hand and the terms of any Order Form issued hereunder on the other hand, the terms of any
such Order Form shall control unless expressly stated otherwise in this Agreement, as applicable.</p>
        </li>
        <li>
            <p>10.4. <strong>"Alpha" or "Beta" Services.</strong>The Platform and Services do not include “Alpha” or “Beta” Service
offerings. Company acknowledges that, notwithstanding anything to the contrary in this Agreement, ANY
“Alpha” or “Beta” Service offerings are offered “AS-IS” FOR INTERNAL DEMONSTRATION, TEST OR
EVALUATION PURPOSES ONLY AND WITHOUT WARRANTY. Such services may contain bugs, errors, and
other defects. Wunderkind does not make any representations, promises, or guarantees that such Service
offerings will be publicly announced or made generally available. Wunderkind has no obligation to provide
technical support or continued availability, and such Service offerings may be suspended or terminated at
any time by Wunderkind in its sole discretion with or without notice to Company.</p>
        </li>
        <li>
            <p>10.5. <strong>Third-Party Partners.</strong> Company agrees that if Company directs or instructs Wunderkind to disclose or
transmit data or information to a third-party entity, including email service providers or other Company
contractors (such entities “<strong>Third-Party Partners</strong>”), that Wunderkind is not responsible for the privacy,
security, or integrity of such data or information, and the treatment of such data or information shall be
governed by Company’s relationship or agreement with such Third-Party Partner. Company acknowledges
and agrees that Third-Party Partners are not sub-processors or subcontractors of Wunderkind, and
Wunderkind assumes no responsibility or liability for the acts or omissions of Third-Party Partners. Company will pass Wunderkind necessary data from Third-Party Partner in order to provision the Platform, Services, and Program.
</p>
        </li>
        <li>
            <p>10.6. <strong>Copyright</strong> Wunderkind reserves the right to delete or disable any Company Content, allegedly
infringing copyright(s); to require that Company terminate the accounts of Users who are repeat copyright
infringers; and to forward the information in any copyright-infringement notice to the User who allegedly
provided the infringing content.</p>
        </li>
        <li>
            <p>10.7. <strong>Suspension of Services.</strong>Wunderkind reserves the right to suspend Company’s access to all or any
portion of the Platform and/or Services if it determines, in its sole discretion, that <strong>(i)</strong> Company fails to
timely pay Fees in accordance Section 3.2; <strong>(ii)</strong> Company’s or its Users’ use of the Services are in breach of
this Agreement; ; <strong>(iii)</strong> Company’s use of the Platform and/or Services poses a security or other risk to the
Platform and/or Services or to other users of the Platform and/or Services; or <strong>(iv)</strong> suspension is required
pursuant to a subpoena, court order or other legal process. Wunderkind agrees to notify Company of any
such suspension. Company will remain responsible for all Fees incurred before or during any suspension.
Wunderkind reserves the right, in its sole discretion, to impose reasonable Fees to, upon Company
request, restore archived data from delinquent accounts.</p>
        </li>
        <li>
            <p>10.8. <strong>Text Platform</strong>Subject to Wunderkind's obligations in this Agreement, the Company is responsible for
all actions taken on Text Platform to the extent within Company’s control by the Company or other
authorized users acting directly on Company’s behalf.   Company shall: (i) use the Text Platform in
compliance   with   the   Agreement   and   the   Text   Acceptable   Use   Policy,   as   set   forth   at
https://www.wunderkind.co/terms/text-aup/ (the “Text AUP”); and (ii) ensure that any opt-outs from
Text received outside of the Platform are provided to Wunderkind without undue delay.   When
considering the sufficiency of any consent required under this Agreement, Company must assume that the
Text Platform uses an automatic telephone dialing system or autodialer to send the Text Messages,
although Wunderkind is under no obligation to use any particular type of technology to send the Text
Messages.</p>
<p>10.8.1 For Company’s convenience, Wunderkind provides the following sample terms and
conditions disclosure, but strongly encourages Company to work with its own counsel to ensure these
disclosures accurately represent Company’s practices:</p>
<ul><li>(a) If you elect to receive marketing and support text messages from us, either via our
website or by sending a text message indicating your consent, you are providing
(and signing) your prior express written consent to receive recurring marketing or
promotional and support text messages from us (each, a “Text Message”) sent
through an automatic telephone dialing system. These may include cart abandon
messages. Message frequency varies. This service is optional, and your consent is
not a condition for purchase. You can opt out of receiving any further Text
Messages from this program at any time by replying “STOP” to any Text Message
you receive from us. For help, reply “HELP” to any Text Message you receive from
us or email [fill in Client support email here]. In addition to any fee of which you
are notified, your mobile provider’s message and data rates may apply to our
confirmation Text Message and all subsequent Text Message correspondence
according to your individual rate plan provided by your wireless carrier. Please
consult your mobile service carrier’s pricing plan to determine the charges for
browsing data and sending and receiving Text Messages. Under no circumstances
will we or our affiliates be responsible for any Text messaging or wireless charges
incurred by you or by a person that has access to your wireless device or
telephone number. If your carrier does not permit Text messages, you may not
receive the Text Messages. Neither we nor the wireless carriers (such as T-Mobile)
will be liable for any delays in the receipt of, or failure to deliver, any Text
Messages, as delivery is subject to effective transmission from your network
operator. Text Message services are provided on an “AS IS” basis. Data obtained
from you in connection with any Text Message services may include your cell
phone number, your provider’s name and the date, time and content of your text
messages. We may use this information in accordance with our Privacy Policy to
contact   you   and   to   provide   the   services   you   request   from   us.   For   more
information on how we use telephone numbers, please read our Privacy Policy.</li>
<li>
    <p>(b) QUEBEC, CANADA ONLY: You may elect to receive text messages in either French
or English. To switch your preferred language at any time, send (a) “PASSER AU
FR” to receive French text messages or (b) “SWITCH TO EN” to receive English text
messages</p>
</li>
</ul>
        </li>
        <li>
            <p>10.8.2. For Company’s convenience, Wunderkind provides the following sample privacy statement
disclosure, but strongly encourages Company to work with its own counsel to ensure these disclosures
accurately represent Company’s practices:</p>
    <ul>
        <li>(a) You may elect to receive text messages from us. When you sign up to receive text
messages, we will send you information about promotional offers and more
These messages may use information automatically collected based on your
actions while on our sites and may prompt messaging such as cart abandon
messages (IE Cookies). To the extent you voluntarily opt to have Text notifications
sent directly to your mobile phone, we receive and store the information you
provide, including your telephone number or when you read a text message. You
may opt out of receiving text messages at any time by texting “STOP” to our text
messages. For more information about text messages, see our Terms and
Conditions. All the above categories exclude text messaging originator opt-in data
and consent; this information will not be shared with any third parties, excluding
aggregators and providers of the Text Message services.</li>
    </ul>
        </li>
        <li>
            <p>10.9. <strong>Force Majeure.</strong> Except with respect to Company’s payment obligations, notwithstanding anything to
the contrary in this Agreement, neither Party will be responsible or have any liability for any delay or
failure to perform its non-monetary obligations hereunder to the extent due to unforeseen circumstances
or causes beyond its reasonable control, including but not limited to acts of God, earthquake, fire, flood,
sanctions, embargoes, strikes, lockouts or other labor disturbances, civil unrest, failure, unavailability or
delay of suppliers or licensors, riots, terrorist or other malicious or criminal acts, war, failure or
interruption of the Internet or third-party internet connections or infrastructure, power failures, acts of
civil and military authorities and severe weather (in each case, “Force Majeure”). The affected Party will
give the other Party prompt written notice (when possible) of the failure to perform due to Force Majeure
and use its reasonable efforts to limit the resulting delay in its performance.</p>
        </li>
        <li>
            <p>10.10. <strong>Relationship of the Parties.</strong>The Parties are independent contractors and nothing in this Agreement
creates a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between or
among the Parties. Wunderkind may subcontract responsibilities under this Agreement but remains
responsible for its breach of this Agreement by the acts or omissions of Wunderkind or its subcontractors.
No person or entity not a Party to this Agreement will be deemed to be a third-party beneficiary of this
Agreement or any provision hereof.</p>
        </li>
        <li>
            <p>10.11. <strong>Eligibility and Scope.</strong>To use the Platform and Services, Company represents and warrants that
Company is competent to enter into this Agreement and that Company and its Users are each at least 18
years of age. If the laws where Company or its Users reside require that Company or its Users be older for
Wunderkind to lawfully provide the Platform and Services to them and use their personal data without
parental consent, then Company or its Users must be that older age. If the representations and warranties
above in this Section 10.14 are not true, or if Wunderkind has previously prohibited Company or its Users
from accessing or using the Platform and Services, Company or such Users may not access or use the
Platform and Services.</p>
        </li>
        <li>
            <p>10.12. <strong>Assignment</strong>Wunderkind may assign this Agreement without restriction. Company may not assign
its rights or delegate its duties under this Agreement either in whole or in part without Wunderkind’s prior
written consent, except that Company may assign this Agreement in whole to one of Company’s Affiliates,
or to a successor in interest as part of a corporate reorganization, consolidation, merger, or sale of all or
substantially   all   of   its   assets.   Company   shall   promptly   provide   notice   to   Wunderkind   including
documentation   sufficient   to   evidence   completion   of   any   permitted   assignment.   Any   attempted
assignment in violation of the foregoing shall be void. This Agreement will bind and inure to the benefit of
each Party and their respective successors and permitted assigns.</p>
        </li>
        <li>
            <p>10.13. <strong>Export Restriction and Compliance with Laws.</strong>Company acknowledges that the Services are subject
to U.S., foreign, and international export controls and economic sanctions laws and regulations and agrees
to comply with all such applicable laws and regulations, including, but not limited to, the U.S. Export
Administration Regulations (“EAR”) and regulations promulgated by the U.S. Department of the Treasury’s
Office of Foreign Assets Control (“OFAC”). Company also specifically agrees not to, directly or indirectly,
allow access to or use of the Services in embargoed or sanctioned countries/regions, by sanctioned or
denied   persons,   or   for   prohibited   end-uses   under   U.S.   law   without   authorization   from   the   U.S.
government. Both Parties also agree to comply with all other laws, rules, and regulations applicable to that
Party under this Agreement.</p>
        </li>
        <li>
            <p>10.14. <strong>Audit.</strong>To the extent permitted by applicable law, Company agrees to allow Wunderkind to audit
Company’s compliance with this Agreement.</p>
        </li>
        <li>
            <p>10.15. <strong>Notices.</strong> All notices or other communications required or permitted under this Agreement shall be
in writing and shall be deemed sufficiently given if delivered by personal delivery, by courier, reputable
overnight delivery service or by certified United States mail, addressed to the party to whom directed at
the applicable address shown above.  Any such notice or communication shall be deemed to have been
delivered at the time of receipt.  Any notices sent to Wunderkind shall be sent as provided in this Section
and also by electronic mail concurrently to legal@wunderkind.co. Wunderkind may provide Company
with general notices through in-product messaging or dashboards, which shall likewise be deemed
effective immediately.</p>
        </li>
        <li>
            <p>10.16. <strong>Entire Agreement.</strong>This Agreement sets forth the entire agreement between the parties pertaining
to this subject matter and supersedes all prior negotiations, understandings and agreements between the
parties whether written or oral concerning this subject matter. The terms of this Agreement may be
updated by Wunderkind from time to time without notice (but will be identified by the last updated date)
and may be reviewed at <a href='http://www.wunderkind.co/self-service-platform-TOS'>www.wunderkind.co/self-service-platform-TOS</a>. Company’s continued access to
and use of the Services constitutes acceptance of the then-current terms.</p>
        </li>
        <li>
            <p>10.17. <strong>Miscellaneous Terms.</strong>Captions and headings are used herein for convenience only, are not a part of
this Agreement, and shall not be used in interpreting or construing this Agreement. Any provision of this
Agreement that by its nature is reasonably intended to survive beyond its termination or expiration shall
survive. Except as otherwise explicitly specified to the contrary, the word “including” will be construed as
“including without limitation”. If any provision of this Agreement is declared by a court of competent
jurisdiction to be invalid, illegal, or unenforceable, such provision shall be modified by the court and
interpreted to best accomplish the objectives of the original provision to the fullest extent permitted by
law, and the other provisions of this Agreement shall remain in full force and effect. Third-Party Sellers do
not have the right to make modifications to this Agreement or to make any additional representations,
commitments, or warranties binding on Wunderkind, its Affiliates, or its Representatives. No waiver or
amendment of any term or condition of this Agreement shall be valid or binding on any Party unless
agreed to in writing by such Party. Wunderkind’s failure to enforce any term of this Agreement will not be
construed as a waiver of the right to enforce any such terms in the future. Unless otherwise specified,
remedies are cumulative. This Agreement is agreed to through purchase (including automatic renewals)
and/or use of the Services.</p>
        </li>
        <li>
            <p>10.18. <strong>Choice of Law and Disputes.</strong>This Agreement and any disputes arising out of or related hereto shall
be governed by and construed in accordance with the laws of the State of New York, without giving effect
to its conflicts of laws rules, the United Nations Convention on the International Sale of Goods, or the
Uniform Computer Information Transactions Act. The exclusive venue for all disputes arising out of or
relating in any way to this Agreement shall be the United States District Court for the Southern District of
New York, and the New York Supreme Court in New York, NY, and the parties consent to personal and
exclusive jurisdiction in those venues. Notwithstanding the foregoing, Company agrees before taking any
formal action that Company will contact Wunderkind at the email address legal@wunderkind.co and
provide a brief, written description of the dispute and Company’s contact information (including
Company’s username, if Company’s dispute relates to an account). Except for intellectual property and
small claims court claims, the Parties agree to use commercially reasonable efforts to settle any dispute,
claim,  question,  or  disagreement  directly  through  consultation  with  Wunderkind,  and  good  faith
negotiations shall be a condition to Company initiating legal action.</p>
        </li>
        <li>
            <p>10.19. <strong>Modification to this Agreement.</strong>Wunderkind may amend the Agreement from time to time due to
changes to the Platform, Services, or Documentation, to account for developments under the law, or for
any other reason. When material modifications are made, Wunderkind may (and where required by law,
will) send an email to you at the last email address you provided to us pursuant to the Agreement to
provide an updated copy of the Agreement. Wunderkind may require you to provide affirmative
acknowledgement to the updated Agreement in a specified manner before further use of the Services is
permitted. If you do not agree to any change(s) after receiving a notice of such change(s), you shall stop
using the Services. Otherwise, your continued use of the Services constitutes your acceptance of such
change(s). PLEASE REGULARLY CHECK WUNDERKIND’S WEBSITE TO VIEW THE THEN-CURRENT TERMS.</p>
        </li>
    </ul>
</ol>
        </div>
    </MainLayout>
  )
}

export default SelfServiceTOS